B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'AkashaCreateEditorController', ['$scope', '$timeout', '$http',  '$window',($scope, $timeout, $http, $window) ->
  
  $scope.init = () ->
    $scope.creating =  false
    #$scope.create_editor()
    
  $scope.create_editor = () ->
    $scope.creating =  true
    $.post("/create_editor", {nothing: true})
      .then (data) ->
        console.log(data)
        console.log(data)
        window.location = "/social_networks/" + data.social_network_id + "/yearbooks"

        

]


B2cApp.controller 'AkashaFinalOrderController', ['$scope', '$timeout', '$http',  '$window',($scope, $timeout, $http, $window) ->
  
  $scope.init = () ->
    $timeout( () ->
      $scope.share_story_modal =  new bootstrap.Modal(document.getElementById("share_story"), {})
      $scope.share_story_modal.show()
    , 1000)
    
  $scope.close_modal = () ->
    $scope.share_story_modal.hide()
    
  $scope.send_share_app = (social_network_id) ->
    $.post("/share_story", {social_network_id: social_network_id})
      .then (data) ->
        $("#share_story_question").hide()
        $("#share_story_thanks").show()
        $("#share_story .modal-footer .btn-primary").hide()
        $timeout( () ->
          $scope.share_story_modal.hide()
        , 2000 )
]
