B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'FooterController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = () ->
    
  $scope.open_contact_modal = () ->
    $scope.end_contact = false
    $scope.modal_contact = new bootstrap.Modal(document.getElementById('modal_contact'), {})
    $scope.modal_contact.show()
    
  $scope.send_delete_account = () ->
    $.post("/send_delete_account", {email: $scope.email})
      .then (response) ->
        $scope.email = null
        $("#send_demande").show()
        $scope.apply_scope()
        $timeout( () ->
          $("#send_demande").hide()
        , 30000)
        
  $scope.send_form_contact = () ->
    $.post("/send_contact", {contact: $scope.contact})
      .then (response) ->
        $scope.end_contact = true
        $scope.contact = {}
        $scope.apply_scope()
        
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
      
  $scope.close_contact_modal = () ->
    $scope.modal_contact.hide()
]
