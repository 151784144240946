B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'PaymentController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init_final_akasha = (current_social_network_id, current_yearbook_id, order, current_delivery_price) ->
      $scope.current_social_network_id = current_social_network_id
      $scope.current_yearbook_id = current_yearbook_id
      $scope.order = order
      $scope.editing_delivery = false
      $scope.add_delivery_price = 0
      $scope.order.delivery_price = current_delivery_price


  $scope.init_akasha = (products, product, order) ->
    $scope.products = products
    $scope.product = product
    $scope.order = order
    $scope.current_order_id = null
    $scope.add_delivery_price = 0
    
    if $scope.order != null
      $scope.current_order_id = $scope.order.id
    console.log($scope.product)
  
  $scope.add_quantity_akasha = (product) ->
    product.quantity = product.quantity + 1
    $.post("/a_adjust_quantity_order", {main_type: "add", order_id: $scope.current_order_id, product_id: product.product_id})
      .then (response) ->
        $scope.products = response.products
        if $scope.products.length > 1
          $scope.product = response.product
        else
          $scope.product.quantity = product.quantity
          $scope.product.price = response.sub_total
        $scope.apply_scope()
    #$scope.update_total()
  
  $scope.valid_promo = () ->
    #ok
    #if $scope.order.id == undefined && response.result == true
    #  window.location = "/a_order?code_promo="+$scope.code_promo
    $.post("/social_networks/"+0+"/orders/send_code_promo", {order_id: $scope.current_order_id, code_promo: $scope.promo})
      .then (response) ->
        if response.result == true
          if $scope.current_order_id == null
            window.location = "/a_order?code_promo="+$scope.promo
          else
            location.reload()
        else
          $("#error_promo").show()
          $scope.promo = ""
          $scope.apply_scope()
          $timeout( () ->
            $("#error_promo").hide()
          , 10000 )
    
  $scope.remove_quantity_akasha = (product) ->
    if product.quantity > 1
      
      $.post("/a_adjust_quantity_order", {main_type: "less", order_id: $scope.current_order_id, product_id: product.product_id})
        .then (response) ->
          console.log(response)
          $scope.products = response.products
          if $scope.products.length > 1
            $scope.product = response.product
          else
            $scope.product.quantity = product.quantity - 1
            $scope.product.price = response.sub_total
          #$scope.product = response.product
          #$scope.product.price = response.sub_total
          $scope.apply_scope()
  
  
  $scope.wememberz_change_country = (price, country_delivery) ->
    $scope.order.delivery_country = country_delivery
    $scope.add_delivery_price_popup = price
    
  $scope.akasha_change_country_final_step = (price) ->
    $scope.add_delivery_price = price - $scope.order.delivery_price
    if $scope.add_delivery_price < 0
      $scope.add_delivery_price = 0
    $("#amount").val($scope.add_delivery_price)
    
  
  $scope.akasha_change_delivery = (type, delivery_id) ->
    $.post("/a_adjust_delivery", {main_type: type, delivery_id: delivery_id, order_id: $scope.current_order_id})
      .then (response) ->
        console.log(response)
        $scope.product.delivery1 = response.delivery1
        $scope.product.delivery2 = response.delivery2
        $scope.apply_scope()

    
  $scope.init = (current_social_network_id, yearbook_id, current_credit, unit_price, price_without_promo, order, order_products, order_products_delivery, user_credit, address, code_promo_active) ->
    $scope.current_social_network_id = current_social_network_id
    $scope.order = order
    $scope.order_products = order_products
    $scope.order.unit_price = unit_price
    $scope.order.quantity = 1
    $scope.editing_delivery = true
    $scope.current_credit = current_credit
    $scope.order_products_delivery = order_products_delivery
    $scope.price_without_promo = price_without_promo
    $scope.user_credit = user_credit
    $scope.code_promo_active = code_promo_active
    #$scope.code_promo = "BLACKFRIDAY"
    console.log($scope.code_promo_active)
    if $scope.order_products.length == 0
      console.log(address)
      if address != null
        $scope.new_address = {}
        $scope.new_address.firstname = address.firstname
        $scope.new_address.lastname = address.lastname
        $scope.new_address.street = address.street
        $scope.new_address.street2 = address.street2
        $scope.new_address.zip = address.zip
        $scope.new_address.country = address.country
        $scope.new_address.city = address.city
        $scope.new_address.tel = address.tel
      $scope.open_add_address()
    console.log($scope.new_address)
    $scope.apply_scope()
    $scope.update_total()
  
  $scope.send_code_promo = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/send_code_promo", {order_id: $scope.order.id, code_promo: $scope.code_promo})
      .then (response) ->
        location.reload()
        
  $scope.add_quantity = (order_product) ->
    order_product.quantity = order_product.quantity  + 1
    $scope.update_total()
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/update_quantity_order_product", {order_product: order_product})
      .then (response) ->
  $scope.remove_quantity = (order_product) ->
    order_product.quantity = order_product.quantity  - 1
    $scope.update_total()
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/update_quantity_order_product", {order_product: order_product})
      .then (response) ->
    
  $scope.update_total = () ->
    $scope.total_quantity = 0
    $scope.total_yearbook_price = 0
    $scope.total_price = 0
    $scope.total_deivery = 0
    $scope.total_without_promo = 0
    check_add_for_livraison = 0
    price_livraison = 0
    
    console.log($scope.order_products)
    angular.forEach $scope.order_products, (order_product, index) ->
      $scope.total_quantity = $scope.total_quantity + order_product.quantity
      #code promo %
      if $scope.code_promo_active.main_type == 1
        $scope.total_yearbook_price = $scope.total_yearbook_price  + (order_product.quantity * order_product.price)
      else
        if order_product.quantity == 1
          $scope.total_yearbook_price = $scope.total_yearbook_price  + (order_product.quantity * order_product.price)
        else
          $scope.total_yearbook_price = $scope.total_yearbook_price  + order_product.price + ((order_product.quantity - 1) * $scope.price_without_promo)
      $scope.total_without_promo = $scope.total_without_promo + (order_product.quantity * $scope.price_without_promo)
      check_add_for_livraison  = check_add_for_livraison + (order_product.quantity - 1)
      
     
    angular.forEach $scope.order_products_delivery, (order_product, index) ->
      price_livraison = order_product.price
      $scope.total_deivery = $scope.total_deivery + (order_product.quantity * order_product.price)
      
      
    $scope.total_deivery = $scope.total_deivery + (check_add_for_livraison * (price_livraison * 0.5))
    #credit
    #$scope.sub_total = ($scope.total_yearbook_price - $scope.current_credit - $scope.user_credit).toFixed(2)
    #$scope.total_price = ($scope.total_yearbook_price - $scope.current_credit - $scope.user_credit + $scope.total_deivery).toFixed(2)
    $scope.sub_total = ($scope.total_yearbook_price).toFixed(2)
    $scope.total_price = ($scope.total_yearbook_price + $scope.total_deivery).toFixed(2)
    $scope.total_promo = ($scope.total_without_promo.toFixed(2) - $scope.sub_total).toFixed(2)
    $scope.total_price = 0 if $scope.total_price < 0
    $scope.sub_total = 0 if $scope.sub_total < 0
    #####
    $scope.order.price = $scope.total_price
    $scope.apply_scope()
    
  
  $scope.save_akasha_final_delivery_address = () ->
    $scope.saving_delivery = true
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders_delivery", {order: $scope.order})
      .then (response) ->
        $scope.current_order_id = response.order_id
        $scope.saving_delivery = false
        $scope.editing_delivery = false
        $scope.order.id = response.order_id
        $scope.apply_scope()
  
  $scope.delete_order_product = (order_product_id) ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/delete_order_product", {order_product_id: order_product_id})
      .then (response) ->
        location.reload()
  
  $scope.update_devivery_address = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/update_address", {order_product: $scope.updating_order_product, price_delivery: $scope.add_delivery_price_popup})
      .then (response) ->
        $scope.update_address_modal.hide()
        angular.forEach $scope.order_products_delivery, (order_product, index) ->
          order_product.price = $scope.add_delivery_price_popup
        $scope.update_total()
        $scope.apply_scope()
        
  $scope.save_devivery_address = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders/save_address", {order_id: $scope.order.id, address: $scope.new_address, new_delivery_mode: $scope.new_delivery_mode, price_delivery: $scope.add_delivery_price_popup})
      .then (response) ->
        $scope.order_products.push(response.order_product)
        $scope.order_products_delivery.push(response.order_product_delivery)
        $scope.saving_delivery = false
        $scope.editing_delivery = false
        $scope.new_address = {}
        if $scope.add_address_modal != undefined
          $scope.add_address_modal.hide()
        $scope.update_total()
        $scope.apply_scope()
  $scope.openModalEnSavoir = () ->
    $scope.modal_en_savoir = new bootstrap.Modal(document.getElementById('en_savoir_modal'), {})
    $scope.modal_en_savoir.show()
    
  $scope.closeModal_en_savoir = () ->
    $('.modal-en-savoir').modal('hide')

  $scope.save_devivery_address_akasha = () ->
    $scope.saving_delivery = false
    $scope.editing_delivery = false
    $scope.apply_scope()
    
  $scope.edit_address = () ->
    $scope.editing_delivery = true
  
  $scope.go_to_payment_delivary_akasha = () ->
    $.post("/a_orders_delivery", {order: $scope.order, add_price: $scope.add_delivery_price})
      .then (response) ->
         window.location = "/a_payment_order_product?order_product_id=" + $scope.order.id
         
  $scope.go_to_payment_akasha = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders", {order: $scope.order})
      .then (response) ->
         window.location = "/a_final_order_thanks?sn_id="+$scope.current_social_network_id
         
         
  $scope.go_to_commande_akasha = () ->
    $.post("/a_commande", {order: $scope.order})
      .then (response) ->
         window.location = "/a_final_order_thanks2?sn_id="+$scope.current_social_network_id
  
  
  
  
  $scope.go_to_payment = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/orders", {order: $scope.order})
      .then (response) ->
        window.location = "/social_networks/" + $scope.current_social_network_id + "/orders/" + $scope.current_order_id + "/payment"
  
  
  
  $scope.open_update_address = (order_product) ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    
    $scope.new_delivery_mode = "chronopost"
    $scope.updating_order_product = order_product
    $scope.updating_order_product.new_delivery_mode = $scope.new_delivery_mode
    $scope.add_delivery_price_popup = order_product.price

    $timeout( () ->
      $scope.update_address_modal = new bootstrap.Modal(document.getElementById('update_address_modal'), {})
      $scope.update_address_modal.show()
      $scope.apply_scope()
    , 100)
  
  $scope.show_empty_field_for_update = () ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    
    if $scope.order.delivery_country == '' || $scope.order.delivery_country == null
      $scope.alert_delivery_country_empty = true
    if $scope.updating_order_product.firstname == '' || $scope.updating_order_product.firstname == null
      $scope.alert_firstname_empty = true
    if $scope.updating_order_product.lastname == '' || $scope.updating_order_product.lastname == null
      $scope.alert_lastname_empty = true
    if $scope.updating_order_product.street == '' || $scope.updating_order_product.street == null
      $scope.alert_street_empty = true
    if $scope.updating_order_product.zip == '' || $scope.updating_order_product.zip == null
      $scope.alert_zip_empty = true
    if $scope.updating_order_product.city == '' || $scope.updating_order_product.city == null
      $scope.alert_city_empty = true
    if $scope.updating_order_product.tel == '' || $scope.updating_order_product.tel == null
      $scope.alert_tel_empty = true
    if $scope.updating_order_product.country == '' || $scope.updating_order_product.country == null
      $scope.alert_country_empty = true
      
  $scope.show_empty_field = () ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    
    if $scope.order.delivery_country == '' || $scope.order.delivery_country == null
      $scope.alert_delivery_country_empty = true
    if $scope.new_address.firstname == '' || $scope.new_address.firstname == null
      $scope.alert_firstname_empty = true
    if $scope.new_address.lastname == '' || $scope.new_address.lastname == null
      $scope.alert_lastname_empty = true
    if $scope.new_address.street == '' || $scope.new_address.street == null
      $scope.alert_street_empty = true
    if $scope.new_address.zip == '' || $scope.new_address.zip == null
      $scope.alert_zip_empty = true
    if $scope.new_address.city == '' || $scope.new_address.city == null
      $scope.alert_city_empty = true
    if $scope.new_address.tel == '' || $scope.new_address.tel == null
      $scope.alert_tel_empty = true
    if $scope.new_address.country == '' || $scope.new_address.country == null
      $scope.alert_country_empty = true
      
  $scope.open_add_address = () ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    $scope.new_delivery_mode = "chronopost"
    $scope.add_address_modal = new bootstrap.Modal(document.getElementById('add_address_modal'), {backdrop:'static', keyboard:false})
    $scope.add_address_modal.show()
    $scope.add_delivery_price_popup = 8

  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
]


  