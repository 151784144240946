B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'CartController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->

  $scope.init = (order_multiple, products, address) ->
    $scope.order_multiple = order_multiple
    $scope.address = address
    $scope.products = products
    
    
    if order_multiple != null && $scope.address == null
      $scope.address = {}
      $scope.open_add_address()
  
  $scope.add_quantity = (product) ->
    product.item.quantity = product.item.quantity + 1
    $.post("/carts/update_quantity_product", {order_multiple_product_id: product.item.id, quantity: product.item.quantity})
      .then (response) ->
        $scope.order_multiple = response.order_multiple
        $scope.apply_scope()
  
  $scope.send_code_promo = () ->
    $.post("/carts/send_code_promo", {order_multiple_id: $scope.order_multiple.id, code_promo: $scope.code_promo})
      .then (response) ->
        $scope.order_multiple = response.order_multiple
        $scope.apply_scope()
        
  $scope.remove_quantity = (product) ->
    product.item.quantity = product.item.quantity - 1
    if product.item.quantity < 1
      product.item.quantity = 1
    $.post("/carts/update_quantity_product", {order_multiple_product_id: product.item.id, quantity: product.item.quantity})
      .then (response) ->
        $scope.order_multiple = response.order_multiple
        $scope.apply_scope()
  
  $scope.open_add_prodduct = () ->
    $scope.add_prodduct_modal = new bootstrap.Modal(document.getElementById('add_prodduct'))
    $scope.add_prodduct_modal.show()
    $http.get("/carts/get_my_products")
      .then (response) ->
        console.log(response)
        $scope.my_social_networks = response.data.social_networks
        $scope.apply_scope()
    
  $scope.open_add_address = () ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    $scope.new_delivery_mode = "chronopost"
    $scope.add_address_modal = new bootstrap.Modal(document.getElementById('add_address_modal'), {backdrop:'static', keyboard:false})
    $scope.add_address_modal.show()
  
  $scope.show_empty_field = () ->
    $scope.alert_delivery_country_empty = false
    $scope.alert_firstname_empty = false
    $scope.alert_lastname_empty = false
    $scope.alert_street_empty = false
    $scope.alert_zip_empty = false
    $scope.alert_city_empty = false
    $scope.alert_tel_empty = false
    $scope.alert_country_empty = false
    console.log($scope.address.delivery_country)
    console.log($scope.address.firstname)
    if $scope.address.delivery_country == '' || $scope.address.delivery_country == null || $scope.address.delivery_country == undefined
      $scope.alert_delivery_country_empty = true
    if $scope.address.firstname == '' || $scope.address.firstname == null || $scope.address.firstname == undefined
      $scope.alert_firstname_empty = true
    if $scope.address.lastname == '' || $scope.address.lastname == null || $scope.address.lastname == undefined
      $scope.alert_lastname_empty = true
    if $scope.address.street == '' || $scope.address.street == null || $scope.address.street == undefined
      $scope.alert_street_empty = true
    if $scope.address.zip == '' || $scope.address.zip == null || $scope.address.zip == undefined
      $scope.alert_zip_empty = true
    if $scope.address.city == '' || $scope.address.city == null || $scope.address.city == undefined
      $scope.alert_city_empty = true
    if $scope.address.tel == '' || $scope.address.tel == null || $scope.address.tel == undefined
      $scope.alert_tel_empty = true
    if $scope.address.country == '' || $scope.address.country == null || $scope.address.country == undefined
      $scope.alert_country_empty = true
  
  $scope.save_devivery_address = () ->
    $.post("/carts/save_address", {order_multiple_id: $scope.order_multiple.id, address: $scope.address, new_delivery_mode: $scope.new_delivery_mode, price_delivery: $scope.order_multiple.price_delivery_unit, country_delivery_id: $scope.order_multiple.country_delivery_id})
      .then (response) ->
        #$scope.order_products.push(response.order_product)
        #$scope.order_products_delivery.push(response.order_product_delivery)
        #$scope.saving_delivery = false
        #$scope.editing_delivery = false
        #$scope.new_address = {}
        if $scope.add_address_modal != undefined
          $scope.add_address_modal.hide()
        $scope.order_multiple = response.order_multiple
        $scope.apply_scope()
  
  $scope.delete_order_product = (product) ->
    index_to_remove = null
    angular.forEach $scope.products, (local_product, index) ->
      if local_product.id == product.id
        index_to_remove = index
    if index_to_remove != null
      $scope.products.splice(index_to_remove, 1)
    $.post("/carts/delete_order_product", {order_multiple_id: $scope.order_multiple.id, order_multiple_product_id: product.id})
      .then (response) ->
        $scope.order_multiple = response.order_multiple
        $scope.apply_scope()
        
  $scope.change_delivery_country = (delivery) ->
    $scope.order_multiple.country_delivery_id = delivery.id
    $scope.order_multiple.price_delivery_unit = delivery.price
    $scope.order_multiple.delivery_country = delivery.name
    
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
]


  