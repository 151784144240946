

B2cApp.controller 'PictureController', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  $scope.init = (current_social_network_id, albums, open_picture_id, album_id, category) ->
    #alert("cc")
    $scope.current_social_network_id = current_social_network_id
    $scope.albums = albums
    $scope.category = category
    $scope.selected_pictures_ids = []
    $scope.selected_pictures = []
    console.log($scope.albums)
    
    $scope.open_picture_id = open_picture_id
    #if($routeParams.album)
    #  $scope.current_album = $scope.albums[$routeParams.album]
    #else
    if album_id != 0 
      angular.forEach $scope.albums, (a, i) ->
        if a.id == album_id
          $scope.current_album = a
    else
      $scope.current_album = $scope.albums[2]  
    $scope.load_album_pictures()
    document.addEventListener("keydown", $scope.processKeys, false);
    $scope.select_album($scope.current_album)
    $(".chat-content").css("max-height", window.innerHeight)
    
    
  #$scope.photos = [];
  #$scope.selectedPhoto = null;
  #  $scope.selectPhoto = function(photo) {
  #    $scope.selectedPhoto = photo;
  #  }

  
  $scope.processKeys = ()->
    evt = evt || window.event;
    if $(document.activeElement).is( "input" ) == false
      #console.log(evt.keyCode)
      switch evt.keyCode
        when 37
          $scope.previous_picture()
        when 39
          $scope.next_picture()
          
  $scope.add_selected_pictures = (current_picture_id) ->
    console.log("selected !")
    is_selected = 1
    if $scope.current_picture.is_selected == 1
      is_selected = 0
    angular.forEach $scope.albums, (a, i) ->
      if is_selected == 1
        a.picture_number += 1
      else 
        a.picture_number -= 1
    $.post("/api/select_picture", {user_id: user_id, picture_id: $scope.current_picture.id, is_selected: is_selected})
      .then (response) -> 
        console.log(response)
        $scope.current_picture.is_selected = is_selected
       
        if is_selected == 1
          $scope.pictures.push($scope.current_picture)
        else
          index = $scope.pictures.indexOf($scope.current_picture)
          if index != -1
            $scope.pictures.splice(index, 1)
        $scope.apply_scope()


  $scope.load_album_pictures = () ->
    $scope.loading = true
    $("#overwritte_album_id").val($scope.current_album.id)
    $http.get("/social_networks/"+$scope.current_social_network_id+"/pictures?album_id="+$scope.current_album.id)
      .then (response) ->
        $scope.pictures = response.data.pictures
        if $scope.current_album.id != -1 && $scope.current_album.id != -2 && $scope.pictures.length > 0 && $scope.current_album.cover.indexOf("/assets/plateform/group.png") != -1
          $scope.current_album.cover = $scope.pictures[0].src
        $scope.loading = false
        if $scope.pictures.length > 0
          $timeout( () ->
            $('.add_picture_to_album').height($(".picture_element").height() - 10)
            if $scope.open_picture_id != "0"
              picture=  undefined
              angular.forEach $scope.pictures, (p, i) ->
                if p.id == parseInt($scope.open_picture_id)
                  picture = p
              if picture != undefined
                console.log("okiii")
                console.log(picture)
                $scope.show_picture(picture)
              $scope.open_picture_id = "0"
              
          , 500)
    
  $scope.change_album_name = (album)->
    $scope.changing_album_name = album
    $scope.rename_album_modal = new bootstrap.Modal(document.getElementById('remove_album'), {})
    $scope.rename_album_modal.show()

  $scope.add_liked_pictures = (user_id) ->
    console.log('OK')
    is_like = 1
    if $scope.current_picture.is_like == 1
      is_like = 0
    angular.forEach $scope.albums, (a, i) ->
      if a.id == -2
        if is_like == 1
          a.picture_number += 1
        else 
          a.picture_number -= 1
  
  

    $.post("/api/like_picture", {user_id: user_id, picture_id: $scope.current_picture.id, is_like: is_like})
      .then (response) -> 
        console.log(response)
        $scope.current_picture.is_like = is_like
        # on supprime la photo si l'album est favorite
        if $scope.current_album.id == -2
          if is_like == 1
            $scope.pictures.push($scope.current_picture)
          else
            index = $scope.pictures.indexOf($scope.current_picture)
            if index != -1
              $scope.pictures.splice(index, 1)
        $scope.apply_scope()

    
    
  $scope.delete_picture = (picture) ->
    if confirm("Etes vous sûr de vouloir supprimer la photo ?")

    
      $.post("/social_networks/"+$scope.current_social_network_id+"/pictures/delete_picture", {picture_id: $scope.current_picture.id})
        .then (response) ->
          $scope.add_album_modal.hide()
          $scope.load_album_pictures()

  # $scope.add_disliked_pictures = () ->
  #   item.type = "Picture"
  #   item.id = picture_id
  #   user.id = current.user_id
  #   $.post("/api/dislike_picture", {current_user_id: user.id, picture_id: picture.id})
  #     .then (response) -> 
  
  $scope.delete_album = (album) ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/albums/delete_album", {album_id: album.id})
      .then (response) ->
        location.reload()
        
  $scope.send_album_name = () ->
    $scope.rename_album_modal.hide()
    $.post("/social_networks/"+$scope.current_social_network_id+"/pictures/rename_album", {album_id: $scope.changing_album_name.id, name: $scope.changing_album_name.name})
      .then (response) ->
    
  $scope.select_album = (album) ->
    $scope.current_album = album
    $scope.load_album_pictures()
  
  $scope.select_pictures = (pictures) ->
    $scope.current_pictures = pictures
    $scope.load_album_pictures()
    
  $scope.open_add_album = () ->
    $scope.album_name = ""
    $scope.add_album_modal = new bootstrap.Modal(document.getElementById('add_album'), {})
    $scope.add_album_modal.show()
    
  $scope.create_album = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/albums", {album: {name: $scope.album_name}})
      .then (response) ->
        $scope.albums.splice(2, 0, response.album);
        $scope.add_album_modal.hide()
        $scope.current_album = $scope.albums[0]
        $scope.load_album_pictures()
        $scope.apply_scope()
  
  $scope.open_change_album_avatar = (album) ->
    $("#album_id").val(album.id)
    $("#chage_album_avatar").click();
  
  $scope.open_validation_tirages_modal = () ->
    $scope.validation_tirages_modal = new bootstrap.Modal(document.getElementById('validation_tirages_modal'), {})
    $scope.validation_tirages_modal.show()
    
  $scope.show_picture = (picture) ->
    if $scope.category == 'tirages'
      index = $scope.selected_pictures_ids.indexOf(picture.id)
      if index == -1
        $scope.selected_pictures_ids.push(picture.id)
        $scope.selected_pictures.push(picture)
      else
        $scope.selected_pictures_ids.splice(index, 1);
        $scope.selected_pictures.splice(index, 1);
    else
      $scope.add_album_modal = new bootstrap.Modal(document.getElementById('show_picture'), {})
      $scope.add_album_modal.show()
      $scope.current_picture = picture
  
  $scope.previous_picture = ()->
    i = $scope.current_picture.index - 1
    if i < 0
      i = $scope.pictures.length - 1
    $scope.current_picture = $scope.pictures[i]
    $scope.apply_scope()
    
  $scope.next_picture = () ->
    i = $scope.current_picture.index + 1
    if i > ($scope.pictures.length - 1)
      i = 0
    $scope.current_picture = $scope.pictures[i]
    $scope.apply_scope()
    
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
]
