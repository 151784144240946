B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'UploaderCtrl', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = (current_social_network_id, element, upload_from, album) ->
    console.log("init album")
    console.log(album)
    $scope.current_social_network_id = current_social_network_id
    $scope.upload_from = upload_from
    $scope.album = album
    $scope.album_id = 0
    $scope.total_pictures = 0
    $scope.total_pictures_done = 0
    $scope.total_pictures_fail = 0
    $scope.end_of_upload = false
    $scope.counter = 0
    $scope.parent_id = Math.round(new Date().getTime() / 1000)
    $scope.album_id = album.id

    
    $scope.modal = new bootstrap.Modal(document.getElementById('uploader_modal'), {backdrop:'static', keyboard:false})
    $scope.waiting = false
    $scope.new_albums = []
    
    $(element).fileupload
      type: 'POST'
      formData: {soub: "ooijoij", parent_id: $scope.parent_id}
      dropZone: $('.drop_zone_uploader')
      dragover: (event)->
        console.log("------$(#overwritte_album_id).val()")
        console.log($("#overwritte_album_id").val())
        if false#$("#overwritte_album_id").val() == "-1" || $("#overwritte_album_id").val() == "-2"
          return false
        else
          timeout = window.dropZoneTimeout
          if !timeout 
            $scope.uploader_dragover = true
          else
            $timeout.cancel(timeout)
          
          window.dropZoneTimeout = $timeout (->
            window.dropZoneTimeout   = null
            $scope.uploader_dragover = false
          ), 100
          #data.submit();
          $scope.$apply()
      dragenter: (event)->
        $('.ondragover_zone').css("height", $('.chat-content').height())
        $('.ondragover_zone').css("width", $('.chat-content').width())
        $('.ondragover_zone').show()
        event.preventDefault()
        $scope.counter++
        
      dragleave: (event)->
        console.log("dragleave")
        console.log($(event.target).attr('id'))
        #if $(event.target).attr('id') != "new_picture"
        $scope.counter--
        if $scope.counter == 0
          $('.ondragover_zone').hide()
      add: (event, data) ->
        if data.files[0].type.includes("image") == false #false#$("#overwritte_album_id").val() == "-1" || $("#overwritte_album_id").val() == "-2"
          return false
        else
          $scope.counter = 0
          $scope.total_pictures += 1
          if $scope.upload_from == "prepare_tirage" && $scope.current_social_network_id < 0
            $.post("/social_networks/"+$scope.current_social_network_id+"/tirages/create_social_network")
              .then (response) ->
                data.form[0].action = data.form[0].action.replace("social_networks/"+$scope.current_social_network_id+"/albums/"+$("#current_album_id").val()+"/pictures",  "social_networks/"+response.social_network.id+"/albums/"+response.album_id+"/pictures")
                $scope.current_social_network_id = response.social_network.id
                $scope.current_social_network = response.social_network
                data.submit();
                $scope.$apply()
          else
            if data.form != undefined
              data.form[0].action = data.form[0].action.replace("/"+$("#current_album_id").val()+"/pictures", "/"+$("#overwritte_album_id").val()+"/pictures")
            data.submit();
            $scope.$apply()
      
      start: (event, data) ->
        if false#$("#overwritte_album_id").val() == "-1" || $("#overwritte_album_id").val() == "-2"
          #return false
          $('#add_pictures_modal').hide()
          $(".modal-backdrop").css("display", "none");
          $(".ondragover_zone").css("display", "none");
          $scope.modal.show()
        else
          console.log("start");
          $('#add_pictures_modal').hide()
          $(".modal-backdrop").css("display", "none");
          $(".ondragover_zone").css("display", "none");
          $scope.modal.show()
      stop: (event, data) ->
        console.log("stop");
        $timeout( () ->
          if $scope.upload_from == "pictures_panel"
            location.reload()
          else
            $scope.end_of_upload = true
            #if $("#selector_folder option").length == 2
            #  $scope.album_id = "new"
            #else
            $scope.album_id = $($("#selector_folder option")[1]).attr("value")
              
            if $scope.upload_from == "plateform"
              $scope.modal.hide()
              $scope.reset()
              $scope.$parent.load_album_pictures()
            else if $scope.upload_from == "prepare_tirage"
              #$.post("/social_networks/"+$scope.current_social_network_id+"/tirages/create_social_network")
              #  .then (response) ->
              $scope.modal.hide()
              scope_tirage = angular.element(document.getElementById("prepare_tirage")).scope()
              scope_tirage.open_create_galery($scope.current_social_network, $scope.total_pictures_done)
                  
            else if $scope.upload_from == "editor_old"
              scope = angular.element(document.getElementById("interface_editor")).scope()
              scope.open_inspector('picture', true)
              $scope.modal.hide()
              $scope.reset()
          $scope.$apply()
        , 500)
      
      fail: (event, data) ->
        console.log("fail");
        $scope.total_pictures_fail += 1
        $scope.$apply()
      done: (event, data) ->
        console.log("done");
        $scope.total_pictures_done += 1

        $scope.$apply()
  
  #$scope.open_add_pictures = () ->
  #  $scope.open_add_pictures = new bootstrap.Modal(document.getElementById('add_pictures_modal'), {})
  #  $scope.open_add_pictures.show() 
    
  $scope.create_album = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/albums", {album: {name: $scope.album_name}})
      .then (response) ->
        album = response.album
        $scope.album_id = String(album.id)
        if album.user_id == 0
          $('select[id=selector_folder] option:first').html(album.name);
          #$scope.new_albums[0].name = album.name
        else
          $scope.new_albums.push(album)

        $scope.album_name = ""
        $scope.$apply()
        
  $scope.reset = () ->
    $scope.total_pictures = 0
    $scope.total_pictures_done = 0
    $scope.total_pictures_fail = 0
    $scope.end_of_upload = false
  
  $scope.close_modal_after_fail = () ->
    scope = angular.element(document.getElementById("interface_editor")).scope()
    scope.open_inspector('picture', true)
    $timeout( () ->
      $scope.waiting = false
      $scope.modal.hide()
      $scope.reset()
      #$timeout( () ->
      #  console.log("ok1")
      #  console.log(save_album_id)
      #  scope.show_all_pcitures_for_album(save_album_id)
      #, 1500)
    , 3000)

  # $scope.add_liked_pictures = () ->
  #   console.log("ça fonctionne")
  #   item.type = "Picture"
  #   item.id = picture_id
  #   user.d = current.user_id
  #   $.post("/api/like_picture", {current_user_id: user.id, picture_id: picture.id})
  #     .then (response) -> 
    
  
  # $scope.add_disliked_pictures = () ->
  #   item.type = "Picture"
  #   item.id = picture_id
  #   user.id = current.user_id
  #   $.post("/api/dislike_picture", {current_user_id: user.id, picture_id: picture.id})
  #     .then (response) -> 

  $scope.move_pictures = () ->
    $scope.waiting = true
    $.post("/social_networks/"+$scope.album.social_network_id+"/pictures/move_pictures", {parent_id: $scope.parent_id, album_id: $scope.album_id})
      .then (response) ->
        if $scope.upload_from == "editor"
          scope = angular.element(document.getElementById("interface_editor")).scope()
          scope.open_picture_folder = $scope.album_id
          scope.open_inspector('picture', true)
          #scope.close_modal()
          $scope.album_id = 0
          $timeout( () ->
            $scope.waiting = false
            $scope.modal.hide()
            $scope.reset()
            #$timeout( () ->
            #  console.log("ok1")
            #  console.log(save_album_id)
            #  scope.show_all_pcitures_for_album(save_album_id)
            #, 1500)
          , 3000)
        else
          location.reload()
          #window.location.href = "/social_networks/"+$scope.album.social_network_id
  
  #$scope.delete_picture = (picture) ->
  #  confirm("Etes vous sûr de vouloir supprimer la photo ?")
  #  angular.forEach $scope.pictures, (p, i) ->
  #    if p.picture_id == picture.picture_id
  #      $scope.pictures.splice(i, i);
  #  
  #  $.post("/pictures/delete_picture", {picture_id: user.social_network_user_id})
  #    .then (response) ->
  
  $scope.close_modal= () ->
    $('.modal').modal('hide')
    
  $scope.close_modal_after_upload = () ->
    if $scope.upload_from == "album"
      window.location.href = "/social_networks/"+$scope.album.social_network_id+"/albums/"+$scope.album.id
    else if $scope.upload_from == "plateform" || $scope.upload_from == "editor"
      $scope.modal.hide()
      $scope.reset()
      scope = angular.element(document.getElementById("interface_editor")).scope()
      scope.load_album_pictures()
    else if $scope.upload_from == "editor_old"
      scope = angular.element(document.getElementById("interface_editor")).scope()
      scope.open_inspector('picture', true)
      $scope.modal.hide()
      $scope.reset()
      $timeout( () ->
        scope.show_all_pcitures_for_album($scope.album_id)
      , 3000)
]


B2cApp.controller 'UploaderMobileCtrl', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = (current_social_network_id, element, user_id) ->
    $scope.current_social_network_id = current_social_network_id
    #$scope.upload_from = upload_from
    #$scope.album = album
    $scope.album_id = 0
    $scope.total_pictures = 0
    $scope.total_pictures_done = 0
    $scope.total_pictures_fail = 0
    $scope.end_of_upload = false
    $scope.counter = 0
    $scope.parent_id = Math.round(new Date().getTime() / 1000)
    $scope.album_id = -1
    $scope.user_id = user_id

    
    #$scope.modal = new bootstrap.Modal(document.getElementById('uploader_modal'), {backdrop:'static', keyboard:false})
    $scope.waiting = false
    $scope.new_albums = []
    
    $(element).fileupload
      type: 'POST'
      formData: {soub: "ooijoij", parent_id: $scope.parent_id, user_id: $scope.user_id}
      
      start: (event, data) ->
        console.log('start')
        $scope.$parent.loader_text = "Chargement des photos ..."
        $scope.$parent.close_popup_pictures_mobile()
        $scope.$parent.start_loader_editor("Chargement des photos ...")
      stop: (event, data) ->
        console.log("stop");
        $timeout( () ->
          $scope.$parent.stop_loader_editor(1)
          $scope.$parent.pictures_by_album_id = undefined
          $scope.$parent.open_modal_picture($scope.$parent.current_summary_bloc)
        , 500)
      
      fail: (event, data) ->
        console.log("fail");
      done: (event, data) ->
        console.log("done");
        $scope.$apply()
]