B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'GroupController', ['$scope', '$timeout', '$http',  '$window',($scope, $timeout, $http, $window) ->
  
  
  
  $scope.init_with_members = (group, users, username, notification) ->
    $scope.group = group
    $scope.group.open = false
    $scope.group.open_produit = true
    $scope.users = users
    $scope.my_message_to_admin = "Bonjour,\nJe souhaite devenir Admin du projet " + group.name + ".\nA bientôt\n" + username
    $scope.my_mail_to_invit = "Bonjour,\nJe souhaite devenir Admin du projet " + group.name + ".\nA bientôt\n" + username
    $scope.changing_name_from_manage_panel = true
    
    $scope.notifications = notification

    
  
  $scope.open_popup_go_to_ordi_if_needed = (there_is_yearbook) ->
    event.stopPropagation();
    if ($(window).width() < 500)
      if there_is_yearbook
        window.location.href = '/social_networks/'+$scope.group.id+'/books'
      else
        myModal = new bootstrap.Modal(document.getElementById('rappel_app_albums'), {})
        myModal.show()
      
  $scope.init = (group) ->
    $scope.group = group
    $scope.group.open = false
    $scope.group.open_produit = true
    
  $scope.open = () ->
    $scope.group.open = true
    
  $scope.close = () ->
    $scope.group.open = false
  
  $scope.open_product = () ->
    $scope.group.open_produit = true
    
  $scope.open_format = () ->
    $scope.group.open_produit = false
  
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
  $scope.show_changing_name_from_manage_panel = () ->
    #$scope.changing_name_from_manage_panel = true

  $scope.open_signup_modal_modal = () ->
    $scope.modal_register.hide()
    $scope.modal_signup = new bootstrap.Modal(document.getElementById('signup_modal'), {})
    $scope.modal_signup.show()

  $scope.change_album_name = () ->
    $scope.changing_name = true
    $('#change_name_album').focus()
    if ($(window).width() < 500)
      $scope.changing_name = false
  
  $scope.changing_name_func = () ->
    $scope.var_changing_name = true

  $scope.open_manage_group = () ->
    $scope.modal_app = new bootstrap.Modal(document.getElementById('manage_group_' + $scope.group.id), {})
    $scope.modal_app.show()
    $timeout( () ->
      tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipList = tooltipTriggerList.map((tooltipTriggerEl) ->
        new (bootstrap.Tooltip)(tooltipTriggerEl)
      )
    ,500)
  

    
  $scope.openCopyAlbum = (id) ->
    $scope.copy_album = new bootstrap.Modal(document.getElementById('copy_album'), {})
    $scope.modal_app.show() 
  $scope.openAppModal = (id) ->
    $scope.modal_app = new bootstrap.Modal(document.getElementById('app_modal'), {})
    $scope.modal_app.show()
  
  $scope.open_rappel_app = () ->
    $scope.open_rappel_app = new bootstrap.Modal(document.getElementById('rappel_app_albums'), {})
    $scope.open_rappel_app.show()
  
  $scope.open_duplicate_group =() ->
    if ($(window).width() > 500)
      $scope.modal_duplicate_group = new bootstrap.Modal(document.getElementById('duplicate_group_'+ $scope.group.id), {})
      $scope.modal_duplicate_group.show()

  #$scope.leave_group = () -> 
  #  $scope.group.id.hide() 
  #
  $scope.create_copie = () ->
    $scope.creating_copie = true
    $.post("/social_networks/duplicate_group", {social_network_id: $scope.group.id})
      .then (response) ->
        #$scope.modal_duplicate_group.hide()
        $timeout( () ->
          window.location.hash = "#sn" + response.social_network_id
          location.reload()
        , 15000)
  
  $scope.change_notifications_alert = (social_network_id) ->
    $.post("/social_networks/change_notification_alert", {social_network_id: social_network_id, choice: $("#notification_for_" + social_network_id).is(":checked")})
      .then (response) ->
        #$scope.modal_message_to_admin.hide()
  
  $scope.open_ask_to_role_to_admin = (social_network_name, username) ->
    $scope.modal_message_to_admin = new bootstrap.Modal(document.getElementById('ask_to_role_to_admin_'+ $scope.group.id), {})
    $scope.modal_message_to_admin.show()
    $scope.apply_scope()

  $scope.open_others_address = () ->
    $scope.open_others_address = new bootstrap.Modal(document.getElementById('autres_adresses'), {})
    $scope.open_others_address.show()

  $scope.send_message_to_admin = () ->
    $.post("/social_networks/send_message_to_admin", {social_network_id: $scope.group.id, message: $scope.my_message_to_admin})
      .then (response) ->
        $scope.modal_message_to_admin.hide()
  
  $scope.open_my_mail_to_invit = (social_network_name, username) ->
    $scope.modal_message_to_invit = new bootstrap.Modal(document.getElementById('my_mail_to_invit'+ $scope.group.id), {})
    $scope.modal_message_to_invit.show()
    $scope.apply_scope()

  $scope.send_message_to_invit = () ->
    $.post("/social_networks/send_invitation", {social_network_id: $scope.group.id, message: $scope.my_mail_to_invit})
      .then (response) ->
        $scope.modal_message_to_invit.hide()

  $scope.add_role_admin = (user) ->
    user.role = "admin"
    $.post("/social_networks/change_role", {social_network_user_id: user.social_network_user_id, role: "admin"})
      .then (response) ->
    #user.social_network_user_id
  
  $scope.add_role_member = (user) ->
    user.role = "member"
    $.post("/social_networks/change_role", {social_network_user_id: user.social_network_user_id, role: "member"})
      .then (response) ->
  
  $scope.leave_member_akasha = (user, snu_id) ->
    if confirm("Souhaitez-vous retirer "+ user.firstname + " du goupe ?")
      $.post("/social_networks/leave_member", {social_network_user_id: snu_id})
        .then (response) ->
          location.reload()
          
  $scope.leave_member = (user) ->
    angular.forEach $scope.users, (u, i) ->
      if u.user_id == user.user_id
        $scope.users.splice(i, i);
      
    $.post("/social_networks/leave_member", {social_network_user_id: user.social_network_user_id})
      .then (response) ->
  
  $scope.leave_group = (soacial_network_id) ->
    $.post("/social_networks/leave_group", {social_network_user_id: soacial_network_id})
      .then (response) ->
        location.reload()
        
    
  $scope.update_group_name_from_manage_panel = () ->
    $.ajax
      url: "/social_networks/"+$scope.group.id
      type: 'PUT'
      data: {social_network: {name: $scope.group.name}}
      success: (result) ->
        #$scope.changing_name_from_manage_panel = false
        $("#group_title").html($scope.group.name)
        $scope.var_changing_name = false
        $scope.close()
        $scope.apply_scope()
        
  $scope.update_group_name = () ->
    $.ajax
      url: "/social_networks/"+$scope.group.id
      type: 'PUT'
      data: {social_network: {name: $scope.group.name}}
      success: (result) ->
        $scope.changing_name = false
        $scope.close()
        $("#group_title").html($scope.group.name)
        $scope.apply_scope()

]
