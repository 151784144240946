

B2cApp.controller 'RegisterCtrl', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = () ->
    $scope.email_alredy_use = false
    
  $scope.send_email_register = () ->
    $.post("/check_email_registration", {email: $scope.email_registration})
      .then (response) ->
        if response.result == false
          $scope.email_alredy_use = true
        else
          document.location.href = "/users/sign_up?email=" + $scope.email_registration
          
          
          #alert(response.result)
]
