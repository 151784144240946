B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'ChatController', ['$scope', '$timeout', '$http',  '$window',($scope, $timeout, $http, $window) ->
  
  $scope.init = (company_id, group_id) ->
    $scope.group_id = group_id
    $scope.company_id = company_id
    

    
    $timeout( () ->
      #$scope.open_add_pictures.show();
      scroll_to_bottom = document.getElementById('chat-content');
      scroll_to_bottom.scrollTop = (scroll_to_bottom.scrollHeight + 1300);
      
      #$scope.message_list = $(document.getElementsByClassName("chat_message_insertion")[0]);
      #$scope.message_list.addEventListener('DOMNodeInserted', $scope.resetSroll())
      
    , 400)
    if window.innerWidth < 502
      $("header").hide()
  
  $scope.resetSroll = () ->
    #alert("ok")
    
  $scope.send_message = () ->
    $("#new_messages_warning").hide()
    message = $scope.chat_message
    $scope.chat_message = ""
    $.post("/comments", {message: message, item_type: "SocialNetwork", item_id: $scope.group_id})
      .then (response) ->
        $("#chat_"+$scope.company_id+"_"+$scope.group_id).append(response.comment)
        $timeout( () ->
          scroll_to_bottom = document.getElementById('chat-content');
          scroll_to_bottom.scrollTop = (scroll_to_bottom.scrollHeight + 1300);
          console.log(scroll_to_bottom.scrollHeight)
        , 500);
        
  #$scope.open_add_pictures = () ->
  #  $scope.open_add_pictures = new bootstrap.Modal(document.getElementById('add_pictures_modal'), {})
  #  $scope.open_add_pictures.show() 

]
