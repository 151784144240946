B2cApp.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

B2cApp.controller 'UploaderSoundCtrl', ['$scope', '$timeout', '$http', ($scope, $timeout, $http) ->
  
  $scope.init = (element, upload_from, album) ->
    $scope.upload_from = upload_from
    $scope.album = album
    $scope.album_id = 0
    $scope.total_pictures = 0
    $scope.total_pictures_done = 0
    $scope.end_of_upload = false
    $scope.parent_id = Math.round(new Date().getTime() / 1000)
    $scope.modal = new bootstrap.Modal(document.getElementById('uploader_sound_modal'), {backdrop:'static', keyboard:false})
    $scope.waiting = false
    $scope.new_albums = []
    $(element).fileupload
      type: 'POST'
      formData: {soub: "ooijoij", parent_id: $scope.parent_id}
      dropZone: $('.drop_zone_uploader_sound')
      dragover: (event)->
        
        timeout = window.dropZoneTimeout
        if !timeout 
          $scope.uploader_dragover = true
        else
          $timeout.cancel(timeout)
        
        window.dropZoneTimeout = $timeout (->
          window.dropZoneTimeout   = null
          $scope.uploader_dragover = false
        ), 100
        console.log("dragover")
        #data.submit();
        $scope.$apply()
        
      add: (event, data) ->
        console.log("add");
        console.log(data.files[0].type)
        if data.files[0].type.includes("audio") == false
          return false
        else
          $scope.total_pictures += 1
          console.log(data)
          console.log(data.form)
          if data.form != undefined
            data.form[0].action = data.form[0].action.replace("/"+$("#current_album_id").val()+"/pictures", "/"+$("#overwritte_album_id").val()+"/pictures")
          data.submit();
          $scope.$apply()
      
      start: (event, data) ->
        console.log("start");
        $scope.modal.show()
      stop: (event, data) ->
        console.log("stop");
        $timeout( () ->
          $scope.end_of_upload = true
          ##if $scope.upload_from == "editor"
          ##  scope = angular.element(document.getElementById("interface_editor")).scope()
          ##  scope.open_inspector('sound', true)
          ##  $scope.modal.hide()
          ##  $scope.reset()
          $scope.$apply()
        , 500)
      
      fail: (event, data) ->
        console.log("fail");
        $scope.total_pictures_done += 1
        $scope.$apply()
      done: (event, data) ->
        console.log("done");
        $scope.total_pictures_done += 1
        $scope.$apply()
  
  $scope.move_pictures = () ->
    $scope.waiting = true
    $.post("/social_networks/"+$scope.album.social_network_id+"/sounds/move_sounds", {parent_id: $scope.parent_id, album_id: $scope.album_id})
      .then (response) ->
        if $scope.upload_from == "editor"
          scope = angular.element(document.getElementById("interface_editor")).scope()
          scope.open_inspector('sound', true)
          #scope.close_modal()
          $scope.album_id = 0
          $timeout( () ->
            $scope.waiting = false
            $scope.modal.hide()
            $scope.reset()
            #$timeout( () ->
            #  console.log("ok1")
            #  console.log(save_album_id)
            #  scope.show_all_pcitures_for_album(save_album_id)
            #, 1500)
          , 3000)
        else
          location.reload()
          #window.location.href = "/social_networks/"+$scope.album.social_network_id
          
  $scope.create_album = () ->
    $.post("/social_networks/"+$scope.current_social_network_id+"/sounds/create_album_sound", {sound_album: {name: $scope.album_name}})
      .then (response) ->
        album = response.album
        $scope.album_id = String(album.id)
        if album.user_id == 0
          $('select[id=selector_folder] option:first').html(album.name);
          #$scope.new_albums[0].name = album.name
        else
          $scope.new_albums.push(album)

        $scope.album_name = ""
        $scope.$apply()
        
  $scope.reset = () ->
    $scope.total_pictures = 0
    $scope.total_pictures_done = 0
    $scope.end_of_upload = false
    
    
  $scope.close_modal_after_upload = () ->
    if $scope.upload_from == "editor"
      scope = angular.element(document.getElementById("interface_editor")).scope()
      scope.open_inspector('sound', true)
      $scope.modal.hide()
      $scope.reset()
    else if $scope.upload_from == "flux"
      location.reload()
]
